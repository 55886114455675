import { useQuery } from '@apollo/client';
import React from 'react';

import {
  EnterpriseInvitedMemberQueryResult,
  EnterpriseInvitedMemberQueryVariables,
  enterpriseInviteQuery,
} from '@/client/queries/invite';
import ErrorLayout from '@/layout/ErrorLayout';
import LoadingLayout from '@/layout/LoadingLayout';

const useInvitedMember = (memberId: number, token: string) => {
  const skip = !memberId || !token;
  const { error, data, loading } = useQuery<
    EnterpriseInvitedMemberQueryResult,
    EnterpriseInvitedMemberQueryVariables
  >(enterpriseInviteQuery, {
    variables: { memberId, token },
    skip,
  });

  const component = loading ? (
    <LoadingLayout />
  ) : error ? (
    <ErrorLayout code={error.message === '권한이 없습니다.' ? 401 : 500} />
  ) : null;

  const account = data ? data.enterpriseInvitedMember.account : null;
  const user = data ? data.enterpriseInvitedMember.user : null;
  const status = data ? data.enterpriseInvitedMember.status : 'ADMIN';
  const phoneData = data?.enterpriseInvitedMember?.phone
    ? {
        phone: data.enterpriseInvitedMember.phone,
        phoneCountryCode: data.enterpriseInvitedMember.phoneCountryCode,
      }
    : null;

  return {
    error,
    account,
    user,
    status,
    phoneData,
    loading,
    component,
  };
};

export default useInvitedMember;
