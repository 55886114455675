import { useMutation } from '@apollo/client';
import { useRouter } from 'next/router';
import { useContext } from 'react';

import { Context, ContextActions } from '@/client/context';
import {
  loginMutation,
  LoginMutationResult,
  LoginMutationVariables,
} from '@/client/queries/auth';
import { accessAllowedMemberStatus } from '@/client/queries/members';
import { setStorageToken } from '@/utils/storage';

const useLogInHook = (
  autoLogin: boolean,
  onSetError?: (errorMessage: string) => void,
) => {
  const { push } = useRouter();
  const { contextDispatch } = useContext(Context);

  const [login] = useMutation<LoginMutationResult, LoginMutationVariables>(
    loginMutation,
    {
      onCompleted: ({
        login: {
          token,
          refreshToken,
          user: {
            phoneVerified,
            emailVerified,
            firstName,
            lastName,
            imageUrl,
            specialties,
            careers,
            enterpriseMemberships,
          },
        },
      }) => {
        if (autoLogin) {
          setStorageToken('local', token, refreshToken);
        } else {
          setStorageToken('session', token, refreshToken);
        }

        const enterpriseMembership = enterpriseMemberships?.find(item =>
          accessAllowedMemberStatus.includes(item.status),
        );
        const firstPermalink = enterpriseMembership?.account?.permalink;

        const fullProfile =
          emailVerified &&
          firstName &&
          lastName &&
          imageUrl &&
          specialties?.length > 0 &&
          careers.length > 0;

        if (!phoneVerified) {
          return push('/create/phone/?force=true');
        } else if (!fullProfile) {
          return push('/create/profile/?force=true');
        } else if (firstPermalink) {
          return push(`/${firstPermalink}/dashboard/insight`);
        } else {
          return push('/create/workspace/?force=true');
        }
      },
      onError: () => {
        if (onSetError) {
          onSetError('휴대전화 번호/이메일 또는 비밀번호를 확인해주세요.');
        } else {
          contextDispatch({
            type: ContextActions.ADD_TOAST,
            value: '휴대전화 번호/이메일 또는 비밀번호를 확인해주세요.',
          });
        }
      },
    },
  );

  const handleLogin = async (data: LoginMutationVariables) => {
    await login({
      variables: {
        ...data,
      },
    });
  };

  return { handleLogin };
};

export default useLogInHook;
