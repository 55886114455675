import styled from '@emotion/styled';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import ButtonNew, {
  ButtonSize,
  ButtonTheme,
} from '@/components/button/ButtonNew';
import CheckboxInput, {
  CheckboxInputSize,
} from '@/components/input/CheckboxInput';
import TextInputNew, {
  TextInputEventProps,
} from '@/components/input/TextInputNew';
import LoadingLayout from '@/components/layout/LoadingLayout';
import SideHeader from '@/components/signup/SideHeader';
import {
  AccountInfoTextBoxWrap,
  SignupContentWrap,
  SignupWrap,
} from '@/components/signup/Styles';
import useAuthHook from '@/hooks/useAuthHook';
import useInvitedMember from '@/hooks/useInvitedMember';
import useLogInHook from '@/hooks/useLogInHook';
import Footer from '@/layout/Footer';
import { colors } from '@/styles/constant';
import { checkEmailRule, checkPhoneNumberRule } from '@/utils/verify';

const IndexPage = () => {
  const { isAuth, checkedAuth } = useAuthHook();
  const router = useRouter();

  const { memberId: inviteId, token: inviteToken } = router.query;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const inviteMode = !!inviteId && !!inviteToken;

  const {
    setError,
    clearErrors,
    formState: { errors },
    setValue,
    watch,
    reset,
  } = useForm<any>({
    defaultValues: {
      id: '',
      password: '',
      autoLogin: false,
    },
  });
  const { id, password, autoLogin } = watch();
  const checkIdType = Number(id);
  const hasError = errors && Object.keys(errors)?.length > 0;

  const { phoneData: invitePhoneData } = useInvitedMember(
    parseInt(inviteId as string),
    inviteToken as string,
  );

  useEffect(() => {
    reset();
  }, [reset, inviteMode]);

  useEffect(() => {
    if (invitePhoneData && !id) {
      setValue('id', invitePhoneData.phone);
    }
  }, [reset, id, invitePhoneData, setValue]);

  const handleSetError = (errorMessage: string) => {
    setError('login', {
      type: 'loginError',
      message: errorMessage,
    });
  };

  const { handleLogin } = useLogInHook(autoLogin, handleSetError);

  if (isAuth || !checkedAuth) return <LoadingLayout />;

  const handleCheckErr = (name?: string) => {
    const checkPhoneId = checkPhoneNumberRule(
      {
        iso2: 'KR',
        name: '대한민국 (82)',
        value: 82,
      },
      id,
    );
    const checkEmailId = checkEmailRule(id);

    if (name === 'id' || !name) {
      if (!id) {
        setError('login', {
          type: 'loginError',
          message: '휴대전화 번호 혹은 이메일을 입력해주세요.',
        });
      } else if (checkIdType ? checkPhoneId : checkEmailId) {
        setError('id', {
          type: 'idError',
          message: `올바른 형식의 ${
            checkIdType ? '휴대전화 번호를' : '이메일을'
          } 입력해주세요.`,
        });
      }
    } else if ((name === 'password' || !name) && !password) {
      setError('password', {
        type: 'passwordError',
        message: '비밀번호를 입력해주세요.',
      });
    } else {
      clearErrors(name);
      clearErrors('login');
    }
  };

  const handleChangeValue = ({ name, value }: TextInputEventProps) => {
    setValue(name, value);

    if (errors) {
      clearErrors(name);
      clearErrors('login');
    }
  };

  const handleClickLogin = async () => {
    const checkIdVariableName = checkIdType ? 'phone' : 'email';

    setIsLoading(true);
    await handleLogin({
      [checkIdVariableName]: id,
      password,
    });
    setIsLoading(false);
  };

  return (
    <>
      <SignupWrap>
        <SideHeader />

        <SignupContentWrap>
          <h2>로그인</h2>
          {!inviteMode && (
            <p>
              집무실 엔터프라이즈는 처음이신가요?{' '}
              <Link href='/signup'>회원가입</Link>
            </p>
          )}

          <AccountInfoTextBoxWrap>
            <Image
              src='/assets/images/logo-rocker-punch-horizontal.png'
              width='91'
              height='24'
              alt='rocketPunch'
            />
            계정으로 이용이 가능합니다.
          </AccountInfoTextBoxWrap>

          <LoginFormGroupStyle>
            <TextInputNew
              name='id'
              value={id}
              isReadOnly={inviteMode}
              placeholder='휴대전화 번호 / 이메일'
              error={!!errors?.login?.message || errors?.id?.message}
              onChange={handleChangeValue}
              onBlur={({ name }: TextInputEventProps) => handleCheckErr(name)}
            />

            <TextInputNew
              name='password'
              type='password'
              placeholder='비밀번호'
              error={errors?.login?.message || errors?.password?.message}
              onChange={handleChangeValue}
              onBlur={({ name }: TextInputEventProps) => handleCheckErr(name)}
              onClickEnter={handleClickLogin}
              value={password}
            />
          </LoginFormGroupStyle>

          <LoginSaveLoginGroupStyle>
            <CheckboxInput
              size={CheckboxInputSize.REGULAR}
              name='autoLogin'
              label='자동 로그인'
              checked={autoLogin}
              onChange={setValue}
            />

            <a href='https://www.rocketpunch.com/login?next_url=%2F'>
              비밀번호를 잊어버리셨나요?
            </a>
          </LoginSaveLoginGroupStyle>

          <ButtonNew
            size={ButtonSize.LARGE}
            theme={ButtonTheme.FILL_BROWN}
            isDisabled={id === '' || password === '' || hasError}
            isLoading={isLoading}
            onClick={handleClickLogin}
          >
            로그인
          </ButtonNew>

          <Footer />
        </SignupContentWrap>
      </SignupWrap>
    </>
  );
};

export default IndexPage;

const LoginFormGroupStyle = styled.div`
  > * + * {
    margin-top: 16px;
  }
`;

const LoginSaveLoginGroupStyle = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 24px 0 33px !important;

  > a {
    color: ${colors.BROWN_NEW};
    font-size: 12px;
    font-weight: 500;
    line-height: 1.5;
    text-decoration: none;
  }
`;
