import PhoneNumber from 'awesome-phonenumber';

import { OptionsProps } from '@/components/dropdown/type';
import { emailReg } from '@/utils/reg';

export interface CountryCodeType extends OptionsProps {
  iso2?: string;
}

export const checkPhoneNumberRule = (
  countryCode: CountryCodeType,
  value?: string,
) => {
  if (!value) {
    return '휴대전화 번호를 입력해주세요.';
  } else {
    const pn = new PhoneNumber(value, countryCode.iso2);
    const { isPossible, valid } = pn.toJSON();
    const errorMessage = '올바른 형식의 휴대전화 번호를 입력해주세요.';

    if (!valid || isPossible === 'too-long' || isPossible === 'too-short') {
      return errorMessage;
    }
  }
};

export const checkEmailRule = (value?: string) => {
  if (!value) {
    return '이메일 주소를 입력해주세요.';
  } else {
    if (!emailReg.test(value)) {
      return '이메일 형식이 올바르지 않습니다.';
    }
  }
};
