import { gql } from '@apollo/client';

import { EnterpriseMemberStatus } from '@/client/queries/members';

export interface EnterpriseInviteUserType {
  id: number;
  pk: number;
}
export interface EnterpriseInviteCompanyType {
  permalink: string;
  id: number;
  pk: number;
  name: string;
  logo: string;
}
export interface EnterpriseInviteAccountType {
  id: number;
  pk: number;
  company: EnterpriseInviteCompanyType;
}
export interface enterpriseInvitedMemberType {
  account: EnterpriseInviteAccountType;
  user: EnterpriseInviteUserType;
  status: EnterpriseMemberStatus;
  phone: string;
  phoneCountryCode: string;
}
export interface EnterpriseInvitedMemberQueryResult {
  enterpriseInvitedMember: enterpriseInvitedMemberType;
}
export interface EnterpriseInvitedMemberQueryVariables {
  memberId: number;
  token: string;
}
export const enterpriseInviteQuery = gql`
  query EnterpriseInvitedMember($memberId: Int!, $token: String!) {
    enterpriseInvitedMember(memberId: $memberId, token: $token) {
      account {
        id
        pk
        company {
          permalink
          id
          pk
          name
          logo
        }
      }
      user {
        id
        pk
      }
      phone
      phoneCountryCode
      status
    }
  }
`;
