import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';

import { TOKEN_KEY_NAME } from '../pages/api/set-tokens';

import { useMember } from '@/client/hooks';
import { MeMemberType } from '@/client/queries/me';
import { accessAllowedMemberStatus } from '@/client/queries/members';
import { getStorageItemOne, removeAllToken } from '@/utils/storage';

const useAuthHook = (isDashboad?: boolean) => {
  const { push, replace } = useRouter();
  const { member } = useMember();
  const [isAuth, setIsAuth] = useState(false);
  const [checkedAuth, setCheckedAuth] = useState(false);

  const handleCheckAuth = useCallback(() => {
    const token = getStorageItemOne('local', TOKEN_KEY_NAME.ACCESS);
    const refreshToken = getStorageItemOne('local', TOKEN_KEY_NAME.REFRESH);

    if (token && refreshToken) {
      return true;
    } else {
      if ((token && !refreshToken) || (!token && refreshToken)) {
        removeAllToken();
      }

      return false;
    }
  }, []);

  useEffect(() => {
    const checkAuth = handleCheckAuth();
    checkAuth && setIsAuth(checkAuth);
  }, [handleCheckAuth]);

  const handleCheckReplace = useCallback(
    (member: MeMemberType) => {
      const enterpriseMembership = member?.enterpriseMemberships.find(item =>
        accessAllowedMemberStatus.includes(item.status),
      );
      const firstPermalink = enterpriseMembership?.account?.permalink;

      const {
        phoneVerified,
        emailVerified,
        firstName,
        lastName,
        imageUrl,
        specialties,
        careers,
      } = member;
      const fullProfile =
        emailVerified &&
        firstName &&
        lastName &&
        imageUrl &&
        specialties?.length > 0 &&
        careers.length > 0;
      if (isDashboad) {
        if (!fullProfile) {
          return '/create/profile/?force=true';
        } else if (!phoneVerified) {
          return '/create/phone/?force=true';
        }
      } else {
        if (!phoneVerified) {
          return '/create/phone/?force=true';
        } else if (!fullProfile) {
          return '/create/profile/?force=true';
        } else if (firstPermalink) {
          return `/${firstPermalink}/dashboard/insight`;
        } else {
          return '/create/workspace/?force=true';
        }
      }
    },
    [isDashboad],
  );

  useEffect(() => {
    if (isAuth && member) {
      const getNextUrl = handleCheckReplace(member);
      getNextUrl && push(getNextUrl);
    } else {
      setCheckedAuth(true);
    }
  }, [isDashboad, replace, isAuth, member, handleCheckReplace, push]);

  useEffect(() => {
    if (!isAuth && checkedAuth && isDashboad) {
      replace('/');
    }
  }, [isAuth, checkedAuth, isDashboad, replace]);

  return { isAuth, checkedAuth };
};

export default useAuthHook;
