import styled from '@emotion/styled';
import Image from 'next/image';
import Link from 'next/link';
import React, { useEffect } from 'react';
import { useRecoilState } from 'recoil';

import { SideContentWrap } from './Styles';

import { hiddenFooterState } from '@/recoil/atoms';
import SVG from '@/svgs';

const SideHeader: React.FC = () => {
  const [_, setHiddenFooterState] = useRecoilState(hiddenFooterState);

  useEffect(() => {
    setHiddenFooterState(true);
    return () => setHiddenFooterState(false);
  }, [setHiddenFooterState]);

  return (
    <SideHeaderWrap>
      <h1>
        <Link href='/'>
          <a>
            <Image
              src='/assets/images/logo-jibmusil-enterprise-white.png'
              width='177'
              height='24'
              alt='執務室 enterprise'
            />
          </a>
        </Link>
      </h1>

      <SideHeaderContent>
        <p>
          공간의 제약이 사라지는
          <br /> 하이브리드 워크,
          <strong>
            <br /> 집무실 엔터프라이즈로
            <br /> 시작하세요!
          </strong>
        </p>
      </SideHeaderContent>

      <SVG.RocketPunchBeigeImage />
    </SideHeaderWrap>
  );
};

export default SideHeader;

const SideHeaderWrap = styled(SideContentWrap)`
  background-image: url('/assets/images/bg-login-side.png');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
`;

const SideHeaderContent = styled.div`
  position: relative;

  > p {
    color: #f8f0e1;
    font-size: 28px;
    font-weight: normal;
    letter-spacing: -1.2px;
    line-height: 1.55;
    text-align: left;

    > strong {
      color: #fff;
      font-weight: bold;
    }
  }
`;
